.set-map{
	height: 400px;
	width: 100% !important;
}
#mapGeo{
	height: 345px;
}
#address-search .input-group{
	margin-bottom: 0;
}
