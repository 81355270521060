//anders web (aw) custom override

//------------------------------- VARIABLES -------------------------------

$aw-primary-color: #303549;
$aw-secondary-color: #62d1f3;
$aw-secondary-color-light: lighten($aw-secondary-color, 20%);

$aw-light-gray: #eeeeee;
$aw-light-blue-dark: darken($aw-secondary-color, 25%);
$aw-danger: #dc3545;
$aw-danger-light: lighten($aw-danger, 10%);
$aw-input-placeholer: #bebebe;
$aw-checkbox: #838691;


@mixin border-light-blue {
  border: 1px solid $aw-secondary-color !important;
}

@mixin card-top-border($pixels, $color) {
  background-color: white;
  border: 0;
  border-top: $pixels solid $color;
}


//------------------------------- LEFT MENU -------------------------------

.pcoded {

  .pcoded-item.pcoded-left-item .pcoded-micon {
    background-color: #484f63 !important;
  }

  &[nav-type="st5"] .pcoded-item.pcoded-left-item > li > a > .pcoded-micon i {
    color: $aw-secondary-color !important;
  }

  .pcoded-navbar {
    .pcoded-item > li > a:hover, .active-link {
      background-color: #484f63 !important;
    }
  }
}


//------------------------------- TOOLTIPS -------------------------------

.tooltip{

  &-inner {
    background-color: $aw-primary-color;
  }

  z-index: 9999999999 !important;

}

.tooltip-content5{

  max-width: 1000px !important;
  min-width: 300px !important;
}

.tooltip-content5 .tooltip-text3 {
  border-bottom: 1px solid $aw-secondary-color;
}

.tooltip-content5::after {
  border-color: transparent transparent transparent;
  //border-color: darkgray transparent transparent;
}

.tooltip-inner2{
  background-color: rgb(253, 253, 253) !important;
  font-size: 14px !important;
  text-align: center;
  border: 1px solid $aw-secondary-color;
  border-bottom: 0;
  color: #2a2a2a;
}

//------------------------------- TABLE -------------------------------

.table{

  &-btn i {
    margin-right: 0;
    padding-right: 0;
    color: white;
  }

  &-style{

    .btn{
      margin-top: -7px
    }

    tbody>tr>td{ height: 35px; padding: 18px 10px 0 10px }
  }

  &-bordered{
    thead>tr>th {
      border-bottom:0;
    }
  }
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr>th:first-child:before {
  top: unset;
  background-color: $aw-secondary-color;
}

tr.group {
  background-color: #e4f6fb !important;
  color: #303548;
  font-weight: bold;
}

a.btn.btn-secondary.buttons-excel.buttons-html5 {
  margin-bottom: 10px;

}

//------------------------------- BUTTONS -------------------------------

.btn{

  &-danger {
    background-color: $aw-danger;
    border-color: $aw-danger;
    cursor: pointer;
    transition: all ease-in 0.3s;

    &:hover {
      background-color: $aw-danger-light;
      border-color: $aw-danger-light;
    }

    &:active {
      background-color: $aw-danger !important;
      border-color: $aw-danger;
      box-shadow: none;
      color: #fff;
    }

  }

  &-darkgray{
    background-color: darkgray;
  }

}

.table-btn .badge-top-right {
  position: absolute!important;
  margin-left: 5px;
  top: -5px !important;
  color: white;
  background-color: white;
}

.table-btn .badge-top-left {
  position: absolute!important;
  margin-left: 5px;
  top: -5px !important;
  color: white;
  background-color: white;
}

.table-btn.mytooltip {
  display: inline-block !important;
}

.badge-tab{
  float: right;
  margin-top: -60px;
  margin-right: -5px;
}

.badge-zindex{
  position: relative;
  left: 5px;
  top:3px;
  cursor: pointer;
}

.badge-zindex :hover
{ color: $aw-secondary-color;
}

//------------------------------- J-PRO FORMS -------------------------------

.j-pro {
  .j-content {
    padding: 0;
  }
  background-color: transparent;
  border: 0;
}

.j-wrapper {
  /*padding: 40px 15px 0px 15px;*/
  padding: 15px 0 0 0;
}

.j-pro {
  .j-divider-text span {
    color: #949494;
    font-size: 14px;
  }
  i {
    color: $aw-primary-color;
  }
  select {
    padding-left: 55px;
    font-size: 14px !important;
    color: #4b4b4b !important;
    &:disabled {
      background-color: $aw-light-gray;
      cursor: not-allowed;
    }
  }
}

.m-b-28{
  margin-bottom: 28px;
}

.m-t-27{
  margin-top: 27px;
}


.j-pro .spanSelect {
  left: 12px;
}

.j-error-view .select2-container--default .select2-selection--single {
  background-color: #ffebee;
}

.j-success-view .select2-container--default .select2-selection--single {
  background-color: #e8f5e9;
}

.checkbox-zoom label {
  font-size: 14px;
}

.j-pro .j-addon-btn {
  background-color: #d9d9d9 !important;
}

.j-pro .j-addon-btn i {
  color: darken(#d9d9d9,50%) !important;
}


//------------------------------- FORMS -------------------------------

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $aw-input-placeholer !important;
  opacity: 1;
  /* Firefox */
  font-size: 14px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $aw-input-placeholer;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $aw-input-placeholer;
}

input, textarea {
  font-size: 14px !important;
  color: #4b4b4b !important;
}

input {
  &:focus, &:hover {
    @include border-light-blue;
  }
}

textarea {
  &:focus, &:hover {
    @include border-light-blue;
  }
}

select {
  &:focus, &:hover {
    @include border-light-blue;
  }
}

input:disabled {
  background-color: $aw-light-gray !important;
  cursor: not-allowed;
}

.clone{

  &-button {
  height: 48px;
  width: 48px;

    i {
      font-size: 14px;
      color: white;
      margin-left: -1px;
    }

  }

}

//------------------------------- FORMS ELEMENTS PLUGINS -------------------------------

.select2-container--default {

  .select2-selection--single {

    .select2-selection__rendered {

      background-color: transparent;
      color: #4b4b4b;
      padding: 10px 30px 10px 55px;
      font-size: 14px !important;
    }
    background-color: #fff;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    .select2-selection__arrow b {
      border-color: $aw-secondary-color transparent transparent transparent;
      margin-top: 2px;
    }
  }
  &.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent $aw-secondary-color transparent;
    padding-top: 2px;
  }
}

.checkbox-fade {
  &.fade-in-inverse .cr, &.zoom-inverse .cr {
    border: 2px solid $aw-checkbox;
    margin-right: 25px;
  }
}

.checkbox-zoom {
  &.fade-in-inverse .cr, &.zoom-inverse .cr {
    border: 2px solid $aw-checkbox;
    margin-right: 25px;
  }
}

.checkbox-fade {
  &.fade-in-inverse .cr .cr-icon, &.zoom-inverse .cr .cr-icon {
    color: $aw-checkbox;
  }
}

.checkbox-zoom {
  &.fade-in-inverse .cr .cr-icon, &.zoom-inverse .cr .cr-icon {
    color: $aw-checkbox;
  }
}

.border-checkbox-section .border-checkbox:disabled ~ .border-checkbox-label {
  cursor: no-drop;
  color: $aw-light-gray;
}

.select2-container--default {

  .select2-selection--multiple {

    .select2-search__field {
      height: 34px !important;
      padding-left: 0;
      font-size: 14px !important;
      color: #4b4b4b !important;
      width: 200px !important;
      border: none !important;
    }
    .select2-selection__choice {
      background-color: $aw-secondary-color;
      border: 1px solid $aw-secondary-color;
      padding: 5px 15px;
      margin-top: 8px;
      color: #fff;
      font-size: 12px !important;
    }
    .select2-selection__rendered {
      box-sizing: border-box;
      list-style: none;
      margin: 0;
      padding: 0px 5px;
      margin-left: 50px;
      width: 100%;
    }
  }
  &.select2-container--focus .select2-selection--multiple {
    border: 1px solid $aw-secondary-color !important;
  }
  .select2-selection--multiple {
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
}

//------------------------------- NAV TABS -------------------------------

.nav-tabs {
  &:first-of-type {
    margin-left: 0;
  }
  .nav-link {
    border-color: $aw-light-gray;
    padding: 1rem 1.1rem;
    margin-left: 7px;
  }
  border-bottom: 0;
  .disabled > a:hover {
    background-color: #f2cccc !important;
    color: white !important;
    cursor: not-allowed;
  }
}

ul.nav a:hover {
  color: #4b4b4b !important;
  background-color: white;
}

//------------------------------- CARD BLOCKS -------------------------------

.card-block {
  padding: 30px 30px 15px 30px;

  &-gray{
    background-color: #f6f7fb;
  }
}

.border-card-style-top{

  &-blue {
    @include card-top-border(2px, $aw-secondary-color-light);
  }

  &-gray {
    @include card-top-border(2px, $aw-secondary-color-light);
  }

}



//------------------------------- PAGES -------------------------------

.page-item.active .page-link{
  color: $aw-primary-color;
  background-color: $aw-light-gray;
  border-color: $aw-light-gray;
}

//bootstrap dropdown buttons

.dropdown{

  &-item {
    color: #666;
    padding: 15px 12px;
    font-size: 13px;

    &:hover{
      background-color: $aw-light-gray !important;
      color: $aw-primary-color !important;
    }
  }

  &-menu {
    width: 100%;
  }

}

//------------------------------- NOTES / CHAT -------------------------------

.widget-chat-box .media .send-chat, .widget-chat-box .media .receive-chat {
  position: relative;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 50px;
  max-width: 400px;
  overflow: visible;
}

.widget-chat-box .media .send-chat {
  background-color: #dff3f9 !important;

}

.widget-chat-box .media .receive-chat {
  background-color: #f7f7f7 !important;
}

.widget-chat-box .media .send-chat:before, .widget-chat-box .media .receive-chat:before {
  content: '';
  position: absolute;
  height: 12px;
  width: 12px;
  border: 10px solid transparent;
  border-right: 15px solid #dff3f9;
  left: -20px;
  top: 8px;
}

.widget-chat-box .media .receive-chat:before {
  left: auto;
  right: -26px;
  border-left: 15px solid #f7f7f7;
  border-right: 15px solid transparent;
}

//------------------------------- CALENDAR -------------------------------
.fc th {
  background: #f6f7fb;
  color: $aw-primary-color;
  border-color: #f6f7fb;
}

.fc-state-active {
  background-color: $aw-primary-color;
  color: #fff;
}

//------------------------------- MODALS -------------------------------

.modal-content {
  -webkit-border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0;
  -moz-background-clip: padding;
  border-radius: 6px;
  background-clip: padding-box;
  -webkit-box-shadow: 0 0 40px rgba(0,0,0,.5);
  -moz-box-shadow: 0 0 40px rgba(0,0,0,.5);
  box-shadow: 0 0 40px rgba(0,0,0,.5);
  color: #000;
  background-color: #fff;
  border: rgba(0,0,0,0);
}
.modal-message .modal-dialog {
  width: 500px;
}
.modal-message .modal-body, .modal-message .modal-footer, .modal-message .modal-header, .modal-message .modal-title {
  background: 0 0;
  border: none;
  margin: 0;
  padding: 0 20px;
  text-align: left;
}

.modal-header {
  display: -ms-flexbox;
  display: block;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e9ecef;
}

.modal-message .modal-title {
  font-size: 17px;
  color: #737373;
  margin-bottom: 3px;
}

.modal-message .modal-body {
  color: #737373;
}

.modal-message .modal-header {
  color: #868e96;
  border-bottom: 1px solid #f3f3f3;
  text-align: center;
  padding: 15px 0 15px 0;
  margin: 0 25px 20px 25px;
}
.modal-message .modal-header .fa,
.modal-message .modal-header
.glyphicon, .modal-message
.modal-header .typcn, .modal-message .modal-header .wi {
  font-size: 30px;
}

.modal-message .modal-footer {
  margin: 20px 25px 0 25px;
  padding: 15px 0 15px 0;
  border-top: 1px solid #f3f3f3;
}
.modal-body .fa-ul li {
  margin-bottom: 10px;
}


.modal-backdrop.in {
  zoom: 1;
  filter: alpha(opacity=75);
  -webkit-opacity: .75;
  -moz-opacity: .75;
  opacity: .75;
}
.modal-backdrop {
  background-color: #060201;
}
.modal-message.modal-success .modal-header {
  color: #53a93f;
  border-bottom: 2px solid #a0d468;
}

.modal-message.modal-info .modal-header {
  color: #57b5e3;
  border-bottom: 2px solid #57b5e3;
  text-align: left;
}

.modal-message.modal-danger .modal-header {
  color: #d73d32;
  border-bottom: 2px solid #e46f61;
}

.modal-message.modal-warning .modal-header {
  color: #f4b400;
  border-bottom: 2px solid #ffce55;
}

.modal-xl {
  width: 90%;
  max-width:1200px;
}

.modal-title{
  float: left;
}

//------------------------------- COLOR PICKER -------------------------------
.minicolors-theme-default .minicolors-swatch {
  top: 14px;
  left: 13px;
  width: 20px;
  height: 20px;
  border: 0;
}

input#color {
  padding-left: 60px;
}

//------------------------------- IMAGE CROPPER -------------------------------

.dropzone .btn i {
  margin-right: 0;
  color: white;
  font-weight: 100;
}


//------------------------------- OTHER -------------------------------
.readonly {
  background-color: transparent !important;
  border: 0 !important;
  border-bottom: 1px dotted #eee !important;
  box-shadow: none;
}

.list-view-media img{
  width: 100px !important;
}









